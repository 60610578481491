import React from 'react';

import { MDFLabel } from './MDFLabel';
import { isEqual, omit } from 'lodash';
import { generateId } from '@adp-wfn/mdf-core';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IMDFLabeledFieldState {
  isValid: boolean;
  touched: boolean;
  validationMessage: string;
  value: any;
}

// This function would be a static method of the class, but since we define the class programmatically,
// there is no class name to use in order to reference static methods from another static method. Since
// this function uses no data from the class, it can be defined outside of the class. We then have to
// also pass the Field being used since we are no longer inside the component context.
const componentHasOwnProperty = (Field: any, props: any) => {
  let initialValue: any;

  // Use the radioGroupValue as the value if the Field is a Radio component.
  // The only way we can tell (at the moment) is by the existence of propTypes.radioGroupValue
  if (Field.propTypes.hasOwnProperty('radioGroupValue')) {
    initialValue = props.radioGroupValue;
  }

  // Use the selectedDate as the value for DatePicker component
  if (Field.propTypes.hasOwnProperty('selectedDate')) {
    initialValue = props.selectedDate;
  }

  // Uses the Phone as the value for Phone component
  if (Field.propTypes.hasOwnProperty('phone')) {
    initialValue = props.phone;
  }

  return initialValue;
};

export function MDFLabeledField(Field: any) {
  return class extends React.Component<any, IMDFLabeledFieldState> {
    static displayName = `MDFLabeled${Field.displayName ?? 'Field'}`;

    state: IMDFLabeledFieldState = {
      isValid: true,
      touched: false,
      validationMessage: '',
      value: null
    };

    validationObject = {
      labelClass: ' mdf-label-error',
      fieldClass: ' vdl-validation-error'
    };

    constructor(props: any) {
      super(props);

      if (props.alwaysShowValidation) {
        this.state.touched = true;
      }
      else if (props.reset) {
        this.state.touched = false;
      }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
      let componentValue = nextProps.value;

      // When the component does not use "value" for props we need to update the componentValue to its appropriate value.
      if (Field.propTypes) {
        const componentPropValue = componentHasOwnProperty(Field, nextProps);
        componentValue = componentPropValue ?? componentValue;
      }

      const valueHasChanged = !isEqual(componentValue, prevState.value);

      if (valueHasChanged || nextProps.isValid !== prevState.isValid) {
        return {
          isValid: nextProps.isValid,
          touched: nextProps.reset === true ? false : prevState.touched,
          validationMessage: nextProps.validationMessage,
          value: componentValue
        };
      }

      // The state hasn't changed, so return null
      return null;
    }

    onFocus = (e: any) => {
      // Pass through focus events.
      this.props.onFocus && this.props.onFocus(e);
      this.setState({ touched: true });
    };

    onBlur = (e: any) => {
      // Validate the current state to catch tab-through
      // in addition to 'normal' blur events.
      this.props.onBlur && this.props.onBlur(e);
    };

    onChange = (value: any) => {
      // All value changes must flow through here. Field components
      // need to signal all value changes through an onChange() handler.
      // listen to any isValid and validationMessages from other components and pass it to validate function.
      this.props.onChange && this.props.onChange(value);
    };

    updateField = (value: any, isValid: boolean, validationMessage: string) => {
      this.updateState({ value, isValid, validationMessage });
    };

    updateState = (validState: any) => {
      this.setState((/* newState, props */) => ({ ...validState }));
    };

    render() {
      // eslint-disable-next-line prefer-const
      let { adpFieldId, className, fieldClassName, hasHtml, helpMessage, id, labelClassName, labelText, name, required } = this.props;

      if (!this.state.isValid && this.state.touched) {
        labelClassName = (labelClassName || '') + this.validationObject.labelClass;
        fieldClassName = (fieldClassName || '') + this.validationObject.fieldClass;

        if (labelText) {
          labelText += this.state.validationMessage;
        }
      }

      let nodeId = id;

      if (!nodeId) {
        nodeId = generateId('mdfLabeledField');
      }

      const ariaLabel = resolveAriaProperty(`Labeled${Field.displayName ?? 'Field'}`, 'aria-label', 'ariaLabel', this.props);

      const passDownProps = omit(this.props, 'aria-label');

      // if aria-labelledby supplied then use this value as Id for label
      // (MDFDropdownList components renders div element instead of input, hence aria-labelledby is used for accessibility
      // else Field component id value assigned to htmlFor attribute so that screen reader will read this associate
      const hasLabelId = this.props?.hasOwnProperty('aria-labelledby');
      const labelId = this.props['aria-labelledby'];

      return (
        <div className={'mdf-validated-field ' + (className || '')}>
          {labelText &&
            <MDFLabel
              {...(hasLabelId ? { id: labelId } : { htmlFor: nodeId })}
              adpFieldId={adpFieldId}
              aria-label={ariaLabel}
              className={labelClassName}
              hasHtml={hasHtml}
              helpMessage={helpMessage}
              labelText={labelText}
              placement="bottom"
              required={required}
            >
            </MDFLabel>
          }
          <Field
            {...passDownProps}
            className={fieldClassName}
            id={nodeId}
            name={name}
            required={required}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
          />
        </div>
      );
    }
  };
}
