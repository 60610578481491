import React from 'react';
import { MDFPopover } from './MDFPopover';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { Overlay } from '@synerg/vdl-react-components';
import { MDFFormValidatedLabel } from './MDFFormValidatedLabel';
import { MDFLabel } from './MDFLabel';
import classNames from 'classnames';
import CheckCircleIcon from 'adp-react-icons/lib/fa/check-circle';
import TimesIcon from 'adp-react-icons/lib/fa/times-circle';

export function MDFFormValidatedField(Field) {
  const FormField = Field;

  return class extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        // Accessibility: this id is used to link form field and label.
        id: this.props.id ? this.props.id : generateId('mdfFormValidatedField'),
        isTouched: false,
        isFocused: false
      };
    }

    static displayName = `Validated${Field.displayName || 'Field'}`;
    private validatedFieldRef = React.createRef<HTMLDivElement>();

    onFocus = (evt) => {
      this.setState({ isFocused: true });
      this.props.onFocus?.(evt);
    };

    onBlur = (evt) => {
      this.setState({ isTouched: true, isFocused: false });
      this.props.onBlur?.(evt);
    };

    // Error messaging may appear as validation popover or inline style
    showValidationPopover = (popoverId) => {
      const popoverErrorClass = 'mdf-overlay-popover vdl-popover--error';
      let isValid = this.isValidField();
      const showValidation = this.props.validateOnRender || this.state.isTouched;
      let validationMsg = this.props.validationObject?.message;

      if ((Field.displayName === 'TimePicker' || Field.displayName === 'MDFDropdownList' || Field.displayName === 'MDFSelectBox') && !this.props.value && this.props.required) {
        validationMsg = FormatHelper.formatMessage('@@mdfInvalidData');
        isValid = false;
      }
      else if (Field.displayName === 'MDFPhone' && !isValid && validationMsg) {
        if (!this.props.required && !this.props.phone) {
          return;
        }
      }

      if (!isValid && validationMsg && showValidation) {
        return (
          <Overlay rootClose={false} show={true} target={this.validatedFieldRef} flip={true} placement="bottom">
            <MDFPopover
              id={popoverId + '_validation'}
              errorId={popoverId}
              containerClassName={popoverErrorClass}
            >
              {this.props.validationObject.message}
            </MDFPopover>
          </Overlay>
        );
      }
    };

    showRulesListValidationPopover = (popoverId) => {
      const rulePassedMsg = FormatHelper.formatMessage('@@RulePassed');
      const ruleFailedMsg = FormatHelper.formatMessage('@@RuleFailed');

      return (
        <Overlay rootClose={false} show={true} target={this.validatedFieldRef} flip={true} placement="bottom">
          <MDFPopover
            id={popoverId + '_rules_validation'}
            errorId={popoverId}
          >
            <div className="validation-check-list__popover-content">
              <h5 className="validation-check-list-heading">
                {this.props.validationObject?.rulesHeading || FormatHelper.formatMessage('@@Entrymust')}
              </h5>
              <ul className="validation-check-list__rules">
                {this.props.validationObject?.rulesList.map((rule, idx) => {
                  return (
                    <li key={idx} className={classNames({
                      'validation-check-list__rule': true,
                      'validation-check-list__rule--success': rule.valid,
                      'validation-check-list__rule--error': !rule.valid
                    })
                    }>
                      <span className="validation-check-list__rule-icon">
                        {rule.valid ? <CheckCircleIcon /> : <TimesIcon />}
                      </span>
                      <span>
                        {rule.message}
                      </span>
                      <span className="sr-only">{(rule.valid ? rulePassedMsg : ruleFailedMsg)}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </MDFPopover>
        </Overlay>
      );
    };

    isValidField = () => {
      const { validationObject } = this.props;
      let valid = true;

      if (validationObject?.rulesList) {
        validationObject.rulesList.forEach((rule) => {
          if (!rule.valid) {
            valid = false;
          }
        });
      }
      else {
        valid = validationObject?.valid;
      }

      return valid;
    };

    renderLabel = () => {
      const { usePopover, validationObject, required, hasHtml, helpMessage, validateOnRender } = this.props;
      let { labelText } = this.props;
      const { id, isTouched } = this.state;
      const isValid = this.isValidField();
      let labelClassName;
      const showValidation = validateOnRender || isTouched;
      let htmlFor;

      if (Field.displayName === 'TimePicker') {
        htmlFor = id + '_input';
      }

      // Handles validation check for tab-through
      if ((Field.displayName === 'TimePicker' || Field.displayName === 'MDFSelectBox') && !this.props.value && this.props.required && showValidation) {
        // Apply error styling
        labelClassName = !isValid && showValidation ? ' mdf-label-error' : undefined;

        // Handles validation check for tab-through
        if (this.props.required && showValidation) {
          if (!this.props.value) {
            labelClassName = ' mdf-label-error';
          }
        }

        // Apply validation message in label
        if (labelText && !usePopover) {
          labelText += ': ' + validationObject.message;
        }

        return (
          labelText && <MDFFormValidatedLabel
            hasHtml={hasHtml}
            helpMessage={helpMessage}
            id={id}
            htmlFor={htmlFor || null}
            labelText={labelText}
            labelClassName={labelClassName}
            required={required}
          />
        );
      }
      else if (Field.displayName === 'MDFPhone') {
        if (showValidation && ((!isValid && this.props.phone) || (!this.props.phone && this.props.required))) {
          labelClassName = ' mdf-label-error';
          if (labelText && !usePopover) {
            labelText += ': ' + validationObject.message;
          }
        }
        else {
          labelClassName = undefined;
        }

        return (
          labelText && <MDFLabel
            hasHtml={hasHtml}
            id={this.props['aria-labelledby']}
            labelText={labelText}
            placement={'top'}
            helpMessage={helpMessage}
            className={labelClassName}
            required={required}
          >
          </MDFLabel>
        );
      }
      else if (Field.displayName === 'MDFDropdownList') {
        labelClassName = !isValid && showValidation ? ' mdf-label-error' : undefined;

        // Handles validation check for tab-through
        if (this.props.required && showValidation) {
          if (!this.props.value) {
            labelClassName = ' mdf-label-error';
          }
        }

        // Apply validation message in label
        if (!isValid && validationObject?.message && showValidation) {
          if (labelText && !usePopover) {
            labelText += ': ' + validationObject.message;
          }
        }

        return (
          labelText && <MDFLabel
            hasHtml={hasHtml}
            helpMessage={helpMessage}
            id={`${id}_mdf_dropdownlist`}
            labelText={labelText}
            className={labelClassName}
            placement="top"
            required={required}
          />
        );
      }
      else {
        labelClassName = !isValid && showValidation ? ' mdf-label-error' : undefined;

        if (this.props.required && showValidation) {
          if (Field.displayName === 'DatePicker') {
            if (!this.props.selectedDate) {
              labelClassName = ' mdf-label-error';
            }
          }
          else if (Field.displayName === 'MDFDualMultiSelect') {
            if (!this.props.selected) {
              labelClassName = undefined;
            }
          }
          else {
            // check if value is empty, null, or undefined.
            if (!this.props.value && this.props.value !== 0) {
              labelClassName = ' mdf-label-error';
            }
          }
        }

        if (!isValid && validationObject?.message && showValidation) {
          if (labelText && !usePopover) {
            labelText += ': ' + validationObject.message;
          }
        }

        return (
          labelText && <MDFFormValidatedLabel
            hasHtml={hasHtml}
            helpMessage={helpMessage}
            id={id}
            htmlFor={htmlFor || null}
            labelText={labelText}
            labelClassName={labelClassName}
            required={required}
          />
        );
      }
    };

    renderFormField = (popoverId) => {
      const { validationObject, validateOnRender, children, className, usePopover, ...passDownProps } = this.props;
      const isValid = this.isValidField();
      let fieldClassName;
      const showValidation = validateOnRender || this.state.isTouched;

      // Applies error style to form field input
      fieldClassName = !isValid && showValidation ? ' vdl-validation-error' + className : '' + className;

      if (Field.displayName === 'MDFDropdownMenu') {
        return (
          <FormField
            {...passDownProps}
            className={fieldClassName}
            isValid={isValid}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            validatedField={true}
            id={this.state.id}
            {...(usePopover && this.state.isFocused && { 'aria-describedby': popoverId })}
          />
        );
      }
      else if (Field.displayName === 'TimePicker') {
        if (this.props.required && showValidation) {
          if (!this.props.value) {
            fieldClassName = ' vdl-validation-error' + className;
          }
        }

        return (
          <FormField
            {...passDownProps}
            className={fieldClassName}
            id={this.state.id}
            {...(usePopover && this.state.isFocused && { 'aria-describedby': popoverId })}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          >
            {children}
          </FormField>
        );
      }
      else if (Field.displayName === 'MDFNumberBox' || Field.displayName === 'DatePicker' || Field.displayName === 'MDFTextbox' || Field.displayName === 'MDFTextarea' || Field.displayName === 'MDFDropdownList' || Field.displayName === 'MDFSelectBox' || Field.displayName === 'MDFCurrencyBox') {
        if (this.props.required && showValidation) {
          if (Field.displayName === 'DatePicker') {
            if (!this.props.selectedDate) {
              fieldClassName = ' vdl-validation-error' + className;
            }
          }
          else {
            // check if value is empty, null, or undefined.
            if (!this.props.value && this.props.value !== 0) {
              fieldClassName = ' vdl-validation-error' + className;
            }
          }
        }

        return (
          <FormField
            {...passDownProps}
            className={fieldClassName}
            id={this.state.id}
            {...(usePopover && this.state.isFocused && { 'aria-describedby': popoverId })}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            aria-invalid={!isValid}
          >
            {children}
          </FormField>
        );
      }
      else if (Field.displayName === 'MDFPhone') {
        return (
          <FormField
            {...passDownProps}
            className={fieldClassName}
            id={this.state.id}
            {...(usePopover && this.state.isFocused && { 'aria-describedby': popoverId })}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          >
            {children}
          </FormField>
        );
      }
      else {
        if (this.props.required && showValidation) {
          if (!this.props.value) {
            fieldClassName = ' vdl-validation-error ' + className;
          }
        }

        return (
          <FormField
            {...passDownProps}
            className={fieldClassName}
            id={this.state.id}
            {...(usePopover && this.state.isFocused && { 'aria-describedby': popoverId })}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          >
            {children}
          </FormField>
        );
      }
    };

    // Rerender on props changes
    static getDerivedStateFromProps() {
      return null;
    }

    render() {
      const { usePopover, validationObject } = this.props;
      // Accessibility: this id is used to link the popover to its corresponding form field.
      const popoverId = generateId('popover');

      return (
        <>
          {this.renderLabel()}
          <div ref={this.validatedFieldRef}>
            {this.renderFormField(popoverId)}
          </div>
          <div aria-live="polite">
            {usePopover && this.state.isFocused && !validationObject.rulesList && this.showValidationPopover(popoverId)}
            {usePopover && this.state.isFocused && validationObject.rulesList?.length > 0 && this.showRulesListValidationPopover(popoverId)}
          </div>
        </>
      );
    }
  };
}
