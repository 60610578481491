import React from 'react';

import { IMDFFieldState } from './FieldState';
import { MDFLabel } from './MDFLabel';
import { MDFPopover } from './MDFPopover';
import { Overlay } from '@synerg/vdl-react-components';
import { isEqual } from 'lodash';
import { ValidationContainerContext } from './MDFContext';
import { generateId } from '@adp-wfn/mdf-core';

export interface IValidatedState {
  isDisabled: boolean;
  isReadOnly: boolean;
  isRequired: boolean;
  isTouched: boolean;
  isValid: boolean;
  message: string;
  value: any;
}

export function MDFValidatedField(Field) {
  return class extends React.Component<any, IMDFFieldState> {
    static displayName = `MDFValidated${Field.displayName ?? 'Field'}`;

    private field: any;
    private name: string;
    private touched = false;
    private validatedState: IValidatedState;
    private validatedFieldRef = React.createRef<HTMLDivElement>();

    declare context: React.ContextType<typeof ValidationContainerContext>;
    static contextType = ValidationContainerContext;

    state: IMDFFieldState = {
      value: null,
      isValid: true,
      validationMessage: '',
      showPopover: false
    };

    validationObject = {
      errorId: 'errorID_' + this.props.id,
      labelClass: ' mdf-label-error',
      fieldClass: ' vdl-validation-error',
      ariaInvalid: true
    };

    constructor(props) {
      super(props);

      if (!props.name) {
        console.warn('Validated fields must have a name attribute! Using an autogenerated name instead.');
      }

      this.name = props.name || generateId('MDFValidatedFieldName');

      let initialValue = props.value;

      if (Field.propTypes) {
        const componentPropValue = this.componentHasOwnProperty(props);

        initialValue = componentPropValue !== null ? componentPropValue : initialValue;
      }

      const validFields = this.validate(initialValue, false, null, null);

      this.state = {
        value: initialValue,
        isValid: props.isValid === false ? props.isValid : validFields.isValid,
        validationMessage: props.serverMessage || validFields.validationMessage,
        showPopover: false
      };

      this.validatedState = {
        isDisabled: this.props.disabled || false,
        isReadOnly: this.props.readOnly || false,
        isRequired: this.props.required || false,
        isTouched: this.touched,
        isValid: this.state.isValid,
        message: this.state.validationMessage,
        value: this.state.value
      };
    }

    componentDidMount() {
      if (this.context.registerField) {
        // Attaching the component to the form
        this.context.registerField(this);
      }
    }

    componentWillUnmount() {
      if (this.context.unregisterField) {
        // Detaching if unmounting
        this.context.unregisterField(this);
      }
    }

    componentDidUpdate() {
      // If the component has isValidComponent method, get validation info from component and
      // update the validated field state if there is mismatch.
      if (this.field?.isValidComponent) {
        let validData = this.field.isValidComponent();

        validData.isValid = (validData.isValid !== false);

        if (validData && validData.isValid && this.props.onValidate) {
          validData = this.props.onValidate(validData);
        }

        if (validData && validData.isValid !== this.state.isValid) {
          this.updateField(validData.value, this.field.props.validateOnRender, validData.isValid, validData.validationMessage);
        }
      }

      if (this.context.updateFieldMeta) {
        this.context.updateFieldMeta(this);
      }
    }

    componentHasOwnProperty(props) {
      let initialValue = null;

      // Use the radioGroupValue as the value if the Field is a Radio component.
      // The only way we can tell (at the moment) is by the existence of propTypes.radioGroupValue
      if (Field.propTypes.hasOwnProperty('radioGroupValue')) {
        initialValue = props.radioGroupValue;
      }

      // Use the selectedDate as the value for DatePicker component
      if (Field.propTypes.hasOwnProperty('selectedDate')) {
        initialValue = props.selectedDate;
      }

      // Use the startDate and endDate as the value for DateRangePicker component
      if (Field.propTypes.hasOwnProperty('startDate') && Field.propTypes.hasOwnProperty('endDate')) {
        initialValue = { startDate: props.startDate, endDate: props.endDate };
      }

      // Uses the Phone as the value for Phone component
      if (Field.propTypes.hasOwnProperty('phone')) {
        initialValue = props.phone;
      }

      // Uses the selected as the value for DualMulti component
      if (Field.propTypes.hasOwnProperty('selected')) {
        initialValue = props.selected;
      }

      // Use the checked value for toggleswitch and checkbox component
      if (Field.propTypes.hasOwnProperty('checked')) {
        initialValue = props.checked;
      }

      return initialValue;
    }

    componentWillReceiveProps(nextProps) {
      let nextValue = nextProps.value;

      // When the component does not use "value" for props we need to update the nextValue to its appropriate value.
      if (Field.propTypes) {
        const componentPropValue = this.componentHasOwnProperty(nextProps);

        // for Checkbox and ToggleButton the checked property is used, when it gets unchecked the componentPropValue returns a false
        // changed the condition to check if componentPropValue is not null then use the componentPropValue
        nextValue = componentPropValue !== null ? componentPropValue : nextValue;
      }

      const propsHasChanged = !isEqual(nextValue, this.state.value);

      // When a new value comes from the app's properties, update and re-validate the field.
      // for custom validations like datepicker, numberbox, phone component we need updateField with isValid and validationMessage from the respective field.
      // Other fields without custom validations would be undefined for this.field.isValid and this.field.validationMessage
      if (propsHasChanged) {
        this.updateField(nextValue, nextProps.validateOnRender, this.field?.isValid, this.field?.validationMessage, this.state.showPopover);
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      let nextValue = nextProps.value;

      // When the component does not use "value" for props we need to update the nextValue to its appropriate value.
      if (Field.propTypes) {
        const componentPropValue = this.componentHasOwnProperty(nextProps);

        // for Checkbox and ToggleButton the checked property is used, when it gets unchecked the componentPropValue returns a false
        // changed the condition to check if componentPropValue is not null then use the componentPropValue
        nextValue = componentPropValue !== null ? componentPropValue : nextValue;
      }

      // WFNMDFP-221: ValidatedField is rerendering irrespective of props change when wrapped with ValidationContainer due to React Context behaviour(ValidationContainerContext).
      // Skip ValidatedField rerender if nextProps value is not in sync with latest state value (user updated value is not yet considered by react-redux).
      if (!isEqual(nextValue, nextState.value)) {
        return false;
      }

      return true;
    }

    private setField = (field: any) => {
      this.field = field;

      if (this.field) {
        if (this.field.props?.validateOnRender && (this.state.isValid === false)) {
          this.updateField(this.state.value, true, this.state.isValid, this.state.validationMessage);
        }

        // Check to see if the component has isValidComponent method
        if (this.field.isValidComponent) {
          const validData = this.field.isValidComponent();

          // updateField only when component updates the isValid property to false
          if (validData && validData.isValid === false) {
            this.updateField(validData.value, this.field.props.validateOnRender, validData.isValid, validData.validationMessage);
          }
        }
      }
    };

    onFocus = (e) => {
      // Pass through focus events.
      if (!this.props.disabled) {
        this.props.onFocus && this.props.onFocus(e);
        this.setState({ showPopover: true }, () => {
          /* DE583630 Fix - When validation container has set of validated components in which some components (from adp-react end) are following focus-mixin async way to fire focus/blur events (this.props.blur()) and
          * other components are firing focus/blur events synchronously. In general, when there is a change in focus, MDFValidatedField component’s blur event must execute first and then focus.
          * But because of mentioned mismatch when control goes from focus-mixin async patterned components to other component which fires events synchronously, focus is executing first followed by blur (it is still in event queue).
          * Focused component is already in touched state so when blur event executes, already touched component will be updated. On component update, "is required" text is showing on the focused one that is not the expected behavior.
          * Now moved setting touched property logic to Event Table through setTimeout. so, it will maintain order in Event Queue.
          */
          setTimeout(() => {
            this.touched = true;
          }, 0);
        });
      }
    };

    onBlur = (e) => {
      // Validate the current state to catch tab-throughs
      // in addition to 'normal' blur events when the field is not disabled.
      if (!this.props.disabled) {
        this.updateField(this.state.value, true, this.state.isValid, this.state.validationMessage);
        this.props.onBlur && this.props.onBlur(e, this.validatedState);
        this.setState({ showPopover: false });
      }
    };

    onChange = (value, isValid, validationMessage, otherParams = {}) => {
      // All value changes must flow through here. Field components
      // need to signal all value changes through an onChange() handler.
      // listen to any isValid and validationMessages from other components and pass it to validate function when the field is not disabled.

      // When usePopover is set to true - wait for setState to finish, before triggering updateField function. Moving updateField as a callback function inside the setState
      // Close the popover onBlur/onChange when immediate property is not set.
      if (!this.props.disabled) {
        this.updateField(value, true, isValid, validationMessage, this.props.usePopover && this.props.immediate, otherParams);
        this.props.onChange?.(value, this.validatedState, otherParams);
      }
    };

    onDropDownMenuChange = (index, value) => {
      this.onChange(value, null, null, { index });
    };

    onPhoneChange = (value, isValid, validationMessage, otherParams) => {
      console.log('MDFValidatedField.onPhoneChange(): Entering. value = ', value);

      if (!this.state.value && typeof value === 'object' && value.phone === '') {
        // When the initial value is empty and the user tabs through the phone number
        // field, the phone number will send back a value object in its onBlur event
        // that is an object with an empty phone field. We need to signal back that
        // the value is still empty.
        this.onChange(this.state.value, isValid, validationMessage, otherParams);
      }
      else {
        // The value likely already changed once, so report back the new value.
        this.onChange(value, isValid, validationMessage, otherParams);
      }
    };

    DateRangeChange = (value, isValid, validationMessage, otherParams = {}) => {
      if (!this.props.disabled) {
        this.updateField(value, true, isValid, validationMessage, this.props.usePopover && this.props.immediate, otherParams);
        this.props.onRangeChange?.(value, this.validatedState, otherParams);
      }
    };

    reset = () => {
      this.touched = false;
      this.setState({ showPopover: false });
    };

    updateField = (value, showValidation, isValid = null, validationMessage = null, showPopover?: boolean, otherParams?: object) => {
      const validState = this.validate(value, showValidation, isValid, validationMessage);
      const params = otherParams ? ((typeof otherParams === 'object') ? otherParams : { otherParams: otherParams }) : null;

      const newValidState = Object.assign({}, { ...validState, ...params }, { showPopover: showPopover });
      this.updateState(newValidState);

      if (this.context.updateFieldState) {
        this.context.updateFieldState(this.name, { ...validState, ...params });
      }
    };

    onComponentValidate = (isValid, validationMessage) => {
      this.setState({ isValid: isValid, validationMessage: validationMessage });
    };

    updateState = (validState) => {
      // Update the object we are passing back in onChange and onBlur
      this.validatedState.isTouched = this.touched;
      this.validatedState.value = validState.value;
      this.validatedState.isValid = validState.isValid;
      this.validatedState.message = validState.validationMessage;

      this.setState({ ...validState });
    };

    validate = (value, showValidation, isValid, validationMessage, showPopover?): IMDFFieldState => {
      // Validation stops if the isValid is already false
      let validState = { value, isValid: isValid !== false, validationMessage: validationMessage ? validationMessage : null, showPopover: showPopover };

      if (showValidation) {
        this.touched = true;
      }

      if (this.props.onValidate) {
        validState = this.props.onValidate(validState);
      }

      return validState;
    };

    showValidationPopover = (errorId) => {
      const { isValid } = this.state;
      // default to colored popover - error
      const popoverErrorClass = 'mdf-overlay-popover vdl-popover--error';

      if (this.props.usePopover && !isValid && (this.props.validateMessage || this.state.validationMessage)) {
        return (
          <Overlay rootClose={false} show={true} target={this.validatedFieldRef} flip={true} placement="bottom">
            <MDFPopover
              id={errorId + '_validation'}
              errorId={errorId}
              containerClassName={popoverErrorClass}
              title={this.props.popoverTitle && this.props.popoverTitle.trim()}>
              {this.props.validateMessage || this.state.validationMessage}
            </MDFPopover>
          </Overlay>
        );
      }
    };

    render() {
      // eslint-disable-next-line prefer-const
      let { adpFieldId, 'aria-invalid': ariaInvalid, className, errorId, fieldClassName, hasHtml, helpMessage, id, labelClassName, labelText, name, placement, required, usePopover, onValidate, ...passDownProps } = this.props;

      const { isValid, validationMessage, showPopover } = this.state;

      // Class is added to label to sync styles with synergy oneUX web Components
      if (!isValid && this.touched && !this.props.disabled) {
        labelClassName = labelClassName ? labelClassName + '' + ' mdf-form-control-label-error' : '' + ' mdf-form-control-label-error';
      }
      else {
        labelClassName = labelClassName ? labelClassName : '' + ' mdf-form-control-label';
      }

      if (!isValid && this.touched && !this.props.disabled) {
        errorId = this.validationObject.errorId;
        labelClassName = (labelClassName || '') + this.validationObject.labelClass;
        fieldClassName = (fieldClassName || '') + this.validationObject.fieldClass;
        ariaInvalid = this.validationObject.ariaInvalid;

        // Append the validationMessage to the label only when usePopover is not true.
        if (labelText && !usePopover) {
          labelText += validationMessage;
        }
      }

      let nodeId = id;

      if (!nodeId) {
        nodeId = generateId('mdfValidatedField');
      }

      const labelProps: any = {};

      if (adpFieldId) {
        labelProps.adpFieldId = adpFieldId;
      }

      if (Field.displayName === 'MDFPhone') {
        labelProps.id = generateId('MDFPhone');

        // Added to make MDFPhone accessible when
        // 'labelText' property is provided without 'aria-labelledby' property
        if (!this.props['aria-labelledby'] && labelText) {
          passDownProps = Object.assign(passDownProps, { 'aria-labelledby': labelProps.id });
        }
      }

      let htmlForId;

      if (Field.displayName === 'MDFPhone') {
        htmlForId = '';
      }
      else if (Field.displayName === 'TimePicker') {
        htmlForId = nodeId + '_input';
      }
      else {
        htmlForId = nodeId;
      }

      let fieldOnChange = this.onChange;

      switch (Field.displayName) {
        case 'MDFDropdownMenu':
          fieldOnChange = this.onDropDownMenuChange;
          break;
        case 'MDFPhone':
          fieldOnChange = this.onPhoneChange;
          break;
      }

      return (
        <div className={'mdf-validated-field ' + className}>
          {Field.displayName === 'MDFDropdownList' ? labelText && <MDFLabel
            {...labelProps}
            className={labelClassName}
            id={`${id}_mdf_dropdownlist`}
            required={required}
            labelText={labelText}
            hasHtml={hasHtml}
            placement={placement || 'top'}
            helpMessage={helpMessage}>
          </MDFLabel> : labelText && <MDFLabel
            {...labelProps}
            htmlFor={htmlForId}
            className={labelClassName}
            required={required}
            labelText={labelText}
            hasHtml={hasHtml}
            placement={placement || 'top'}
            helpMessage={helpMessage}>
          </MDFLabel>}
          <div ref={this.validatedFieldRef}>
            {Field.displayName === 'DataRangePicker' ? <Field
              aria-invalid={ariaInvalid}
              {...passDownProps}
              errorId={errorId}
              name={name}
              className={fieldClassName}
              required={required}
              aria-required={required}
              ref={this.setField}
              validatedField={true}
              onRangeChange={this.DateRangeChange}
              id={nodeId}
            /> : <Field
              aria-invalid={ariaInvalid}
              {...passDownProps}
              errorId={errorId}
              name={name}
              className={fieldClassName}
              required={required}
              aria-required={required}
              ref={this.setField}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              validatedField={true}
              onChange={fieldOnChange}
              id={nodeId}
            />}
          </div>
          <div aria-live="polite">
            {showPopover &&
              <div>
                {this.showValidationPopover(errorId)}
              </div>
            }
          </div>
        </div>
      );
    }
  };
}
