import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { SdfCloseButton, SdfFloatingPane } from '@waypoint/react-components';
import { FormatHelper, MDFCore } from '@adp-wfn/mdf-core';
import { IMDFPopupDialogProps } from './MDFPopupDialog';

export const MDFPopupDialog2 = ({
  onClose,
  className,
  // containerClassName,
  title,
  targetId,
  targetRef,
  children,
  isOpen = false,
  orientation = 'right',
  closeButtonAriaLabel,
  caption,
  disableCloseOnClickOutside
}: IMDFPopupDialogProps) => {
  const floatingPane = useRef<HTMLSdfFloatingPaneElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const [showPane, setShowPane] = useState(isOpen);
  const [isShowing, setIsShowing] = useState(isOpen);
  const dialogClassName = orientation === 'left' ? 'mdf-popup-dialog-left' : 'mdf-popup-dialog';
  const anchor = (targetId ? document.getElementById(targetId) : targetRef && ReactDOM.findDOMNode(targetRef)) as HTMLElement;

  const floatingPaneProps = {
    'show-arrow': true,
    'tab-behavior': 'trap'
  };

  if (disableCloseOnClickOutside === true) {
    floatingPaneProps['disable-close-on-click-outside'] = disableCloseOnClickOutside;
  }

  useEffect(() => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFPopupDialog2.useEffect([isOpen]): Entering. isOpen = ${isOpen}`);
    }

    setShowPane(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFPopupDialog2.useEffect([showPane]): Entering. showPane = ${showPane}`);
    }

    if (showPane) {
      const interval = setInterval(() => {
        if (floatingPane.current) {
          void floatingPane.current.open(anchor);
          setIsShowing(true);
          clearInterval(interval);
        }
      }, 10);
    }
    else {
      void floatingPane.current?.close();
    }
  }, [showPane]);

  const handleAfterClose = useCallback(() => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFPopupDialog2.handleAfterClose(): Entering. isShowing = ${isShowing}, targetId = ${targetId}, targetRef =`, targetRef);
    }

    setIsShowing(false);
    onClose && onClose();

    if (targetId) {
      document.getElementById(targetId).focus();
    }
    else if (targetRef) {
      (ReactDOM.findDOMNode(targetRef) as HTMLElement).focus();
    }
  }, []);

  const handleAfterOpen = useCallback(() => {
    if (MDFCore.shouldLog()) {
      console.log('MDFPopupDialog2.handleAfterOpen(): Entering. content.current =', content.current);
    }

    content.current.focus();
  }, []);

  const handleAttemptToClose = useCallback(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFPopupDialog2.handleAttemptToClose(): Entering. showPane = ${showPane}`);
    }

    setShowPane(false);
  }, []);

  const handleClose = useCallback((event) => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFPopupDialog2.handleClose(): Entering. showPane = ${showPane}, event =`, event);
    }

    event.preventDefault();
    event.stopPropagation();
    setShowPane(false);
  }, []);

  const renderHeader = () => {
    return (
      <div className={'popup-dialog-header'}>
        {title && <h4 className="popup-dialog-title">{title}</h4>}
        <div className="popup-dialog-close">
          <SdfCloseButton
            buttonTitle={closeButtonAriaLabel || FormatHelper.formatMessage('@@Close')}
            className={classNames('mdf-popup-dialog-close-button', className)}
            onSdfClose={handleClose}
            size="sm"
          />
        </div>
      </div>
    );
  };

  const renderDialog = () => {
    return (
      <div className={classNames(dialogClassName, className)}>
        <div className="popup-dialog-content">
          {children}
        </div>
      </div >
    );
  };

  if (MDFCore.shouldLog()) {
    console.log(`MDFPopupDialog2: rendering. showPane = ${showPane}, isShowing = ${isShowing}`);
  }

  if (showPane || isShowing) {
    return (
      <SdfFloatingPane
        {...floatingPaneProps}
        onSdfAfterClose={handleAfterClose}
        onSdfAfterOpen={handleAfterOpen}
        onSdfAttemptToClose={handleAttemptToClose}
        ref={floatingPane}
      >
        <div ref={content} tabIndex={-1} className="mdf-popup-dialog-2 mdf-popup-dialog-container vdl-overlay-container">
          <span className="mdf-sr-only">{caption}</span>
          {renderHeader()}
          {renderDialog()}
        </div>
      </SdfFloatingPane>
    );
  }
  else {
    return null;
  }
};

MDFPopupDialog2.displayName = 'MDFPopupDialog2';
